/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import EditIcon from "../../../../assets/icons/editIcon";
import TrashIcon from "../../../../assets/icons/trashIcon";
import { ConfirmDialogComponent } from "../../components/confirmDialog";
import { EditUserComponent } from "../../components/editUser";
import { Pagination } from "../../../../components/pagination";
import { GetRequest, PostRequest, PutEmployee } from "../../../../includes/functions";
import { EmployeeProps, HistoryProps } from "../../../../includes/types";
import moment from "moment";
import { BaseLoader } from "../../../../components/baseloader";
import { BoxIcon } from "../../icon";
import { DownloadCSV } from "../../../../includes/createCSVFileHook";
import { toast } from "react-toastify";
import { WhiteButton } from "../../../../components/buttons";
import { emitEvent, subscribeEvent, unsubscribeEvent } from "../../../../includes/customEventHook";
import { CONSTANTS } from "../../../../includes/constant";

interface EmployeesTableProps {
  searchText: string;
  onAddNewBusiness?: () => void;
}

const EmployeesTable = (props: EmployeesTableProps) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [updating, setUpdating] = useState<boolean>(false);
  const [showConfirmDailog, setShowConfirmDailog] = useState<boolean>(false);
  const [showEditUser, setShowEditUser] = useState<boolean>(false);
  const [listOfEmployees, setListOfemployees] = useState<EmployeeProps[]>([]);
  const [startDate, setStartDate] = useState<string>(moment().subtract(3, "M").toISOString());
  const [startSearchDate, setStartSearchDate] = useState<number>(0);
  const [endSearchDate, setEndSearchDate] = useState<number>(0);
  const [endDate, setEndDate] = useState<string>(moment().toISOString());
  const [pageSize, setPageSize] = useState<number>(50);
  const [fetching, setFetching] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState<EmployeeProps | null>(null);
  const [loading, setLoading] = useState(false);
  const [historyList, setHistoryList] = useState<HistoryProps[]>([]);
  
  const GetTripsHistory = (page: number) => {
    setLoading(true);
    GetRequest(`trips`,{}, false).then((res) => {
      setLoading(false);
        if (res.status) {
         setHistoryList(res.data.trips);
        }
      }).catch(error => {
        console.log('Fetching employee trips:', error)
      });
  }

  const GetEmployees = (page: number) => {
    setFetching(true);
    GetRequest(
      `employees`,
      {
        page: page,
        pageSize: pageSize,
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD")
      },
      false
    ).then((res) => {
      setFetching(false);
      if (res.status) {
        setListOfemployees(res.data.employees);
        emitEvent(CONSTANTS.Events.reloadEmployees,{
          employeesCount:res.data.employees.length
        })
      }
    }).catch(error => {
      setFetching(false);
      toast.error("Failed to fetch employees.");
      console.error('Error fetching employees:', error);
    });
  };

  const DeleteUser = (employee:EmployeeProps) => {
    setLoading(true);
    PostRequest(`delete:employee/${employee._id}`, { id: employee._id }, true).then((response) => {
      setLoading(false);
      if(response.status)
      {
      setListOfemployees(listOfEmployees.filter((employee) => employee.id !== employee._id));
      setShowConfirmDailog(false);
      }
    }).catch(error => {
      setLoading(false);
    });
  };

  const UpdateUser = (values: { employeeId: string; firstName: string; lastName: string; email: string }) => {
    if (!values.employeeId) {
      console.error('No employeeId provided');
      toast.error("No employee ID provided.");
      return;
    }
    setUpdating(true);
    PutEmployee(values.employeeId, {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
    })
      .then((response) => {
        setUpdating(false);
        if (response.status) {
          GetEmployees(currentPage);
          setShowEditUser(false);
          toast.success("Employee updated successfully.");
        }
      })
      .catch((error) => {
        setUpdating(false);
        toast.error("Failed to update employee.");
        console.error("Update failed:", error);
      });
  };

  const filterEmployees = (employees: EmployeeProps[], searchText: string) => {
    return employees.filter((employee) =>
      String(employee?.firstName).toLowerCase().includes(searchText.toLowerCase()) ||
    String(employee?.email).toLowerCase().includes(searchText.toLowerCase()) ||
      String(employee.lastName).toLowerCase().includes(searchText.toLowerCase()) || (parseInt(moment(employee.createdAt).format("YYYYMMDD")) >= startSearchDate  &&  parseInt(moment(employee.createdAt).format("YYYYMMDD")) <= endSearchDate)
    );
  };
  useEffect(() => {
    GetTripsHistory(1);
    subscribeEvent(CONSTANTS.Events.searchFilter, ({ detail }) => {
      if (detail?.startDate) {
    setStartSearchDate(parseInt(detail?.startDate));
    setEndSearchDate(parseInt(detail?.endDate));
      }
    });
    return () => {
      unsubscribeEvent(CONSTANTS.Events.searchFilter, () => {});
    };
  }, []);
  useEffect(() => {
    GetEmployees(currentPage);
    const handleReloadEmployeeTable = (event: any) => {
      if (event.detail) {
        setEndDate(event.detail.endDate);
        setStartDate(event.detail.startDate);
        setTimeout(() => {
          GetEmployees(1);
        }, 1000);
      }
    };
    window.addEventListener("reloadEmployeeTable", handleReloadEmployeeTable);
    return () => {
      window.removeEventListener("reloadEmployeeTable", handleReloadEmployeeTable);
    };
  }, [currentPage]);

  const Alldata = filterEmployees(listOfEmployees, props.searchText);

  return (
    <>
      <table className="table table-responsive">
        <thead>
          <tr>
            <th scope="col">S.No.</th>
            <th scope="col">Employee ID</th>
            <th scope="col">Employee Name</th>
            <th scope="col">Email Address</th>
            <th scope="col">Total Check-Ins</th>
            <th scope="col">Total Check-Outs</th>
            <th scope="col">Date Joined</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {fetching && (
            <tr>
              <td colSpan={10}>
                <BaseLoader /> <small className="fs-small">Fetching...</small>
              </td>
            </tr>
          )}
          {Alldata.length === 0 && !fetching && (
            <tr>
              <td colSpan={7}>
                <div className="text-center p-5">
                <BoxIcon />
                  <div className="fs-6 fw-bold my-3">No employee added yet</div>
                  <WhiteButton
                onClick={() => {
                  if(props.onAddNewBusiness)
                  {
                  props.onAddNewBusiness();
                  }
                }}
              >
                <span className="mx-2">Add new employee</span>
              </WhiteButton>
                </div>
              </td>
            </tr>
          )}
          {Alldata.map((employee, i) => (
            <tr key={i}>
              <td>{(i + 1) > 9?(i + 1):"0"+(i + 1)}</td>
              <td>{employee.employeeId}</td>
              <td>{`${employee.firstName} ${String(employee?.lastName).replace("undefined","")}`}</td>
              <td>{employee.email}</td>
              <td><GetTotalCheckInOfEmployee 
              id={String(employee._id)}
              /></td>
              <td><GetTotalCheckOutOfEmployee 
              id={String(employee._id)}
              /></td>
              <td>{moment(employee?.createdAt).format("Do, MMM YYYY")}</td>
              <td>
                <div className="d-flex g-3">
                    <button
                      className="btn p-0 px-1"
                      onClick={() => {
                        setSelectedEmployee(employee);
                        setShowEditUser(true);
                      }}
                    >
                      <EditIcon />
                    </button>
                    <button
                      onClick={() => {
                        setSelectedEmployee(employee);
                        setShowConfirmDailog(true);
                      }}
                      className="btn p-0 px-1"
                    >
                      <TrashIcon />
                    </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        onFilterRow={(d) => {
          setPageSize(d);
        }}
        onPage={(d) => {
          GetEmployees(d);
          setCurrentPage(d);
        }}
      />

      {showConfirmDailog && (
        <ConfirmDialogComponent
          onClose={() => setShowConfirmDailog(false)}
          loading={loading}
          confirm={() => DeleteUser(selectedEmployee!)}
        />
      )}

      {showEditUser && (
        <EditUserComponent
          loading={updating}
          employeeData={selectedEmployee}
          onClose={() => setShowEditUser(false)}
          onValue={(id) => {
            GetEmployees(1);
            setShowEditUser(false);
          }}
        />
      )}
    </>
  );
};

export default EmployeesTable;
interface GetTotalCheckInOfEmployeeProp {
id:string;
}
const GetTotalCheckInOfEmployee = (prop:GetTotalCheckInOfEmployeeProp)=>{
  const [count,setCount] = useState<number>(0);
  useEffect(()=>{
    GetRequest(`trips/employee/check-in/${prop.id}`,{}).then((res)=>{
      if(res.status)
      {
        setCount(res.data.checkIns.length)
      }
    })
  },[])
  return <div>{count}</div>;
}
const GetTotalCheckOutOfEmployee = (prop:GetTotalCheckInOfEmployeeProp)=>{
  const [count,setCount] = useState<number>(0);
  useEffect(()=>{
    GetRequest(`trips/employee/check-out/${prop.id}`,{}).then((res)=>{
      if(res.status)
      {
        setCount(res.data.checkIns.completedCheckOuts.length)
      }
    })
  },[])
  return <div>{count}</div>;
}