import React, { ChangeEvent, FormEvent, useState } from "react"
import BaseInput from "../../../../components/baseInput";
import { BaseButton } from "../../../../components/buttons";
import { PostRequest } from '../../../../includes/functions';
import { emitEvent } from "../../../../includes/customEventHook";
import { CONSTANTS } from "../../../../includes/constant";
import { UserProps } from "../../../../includes/types";

interface AddPersonnelComponentprops {
  onClose: () => void;
}

export const AddPersonnelComponent = (props: AddPersonnelComponentprops) => {
  const [loading, setLoading] = useState<boolean>(false)
 const [formData,setFormData] = useState<UserProps>({
  firstName:"",
  lastName:"",
  email:""
 })
 const handleChange = ({target}:ChangeEvent<HTMLInputElement>)=>{
setFormData({
  ...formData,
  [target.name]:target.value
})
 }
 const handleSubmit = (e:FormEvent)=>{
  e.preventDefault();
  setLoading(true);
   PostRequest("employee", formData, true, true).then((res) => {
                setLoading(false);
                if (res.status) {
                  emitEvent(CONSTANTS.Events.reloadEmployees, {})
                  if (props.onClose) {
                    props.onClose()
                  }
                }
        })
 }
 return <div className="modal-x" tabIndex={-1} >
    <div className="modal-dialog" >
      <div className="modal-content" style={{ borderRadius: 20 }}>
        <div className="modal-header">
          <h5 className="modal-title">Invite employee</h5>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={props.onClose}></button>
        </div>
        <div className="modal-body p-3">
          <div style={{ fontSize: 14 }}>New members will have access to their own personal dashboard</div>
          <form
            onSubmit={handleSubmit}
          >
           <div className='' >
              <div className="pt-3" >
                <BaseInput
                  label="First name"
                  name='firstName'
                  type='text'
                  max={20}
                  placeholder="Enter first name"
                  onValueChange={handleChange}
                  value={String(formData?.firstName).replace(/[ ]/g, '').trim()}
                  required
                   />
                <BaseInput
                  label="Last name"
                  name='lastName'
                  type='text'
                  max={20}
                  placeholder="Enter last name"
                  onValueChange={handleChange}
                  value={String(formData?.lastName).replace(/[ ]/g, '').trim()}
                  required
                />
                <BaseInput
                  label="Employee's email"
                  name='email'
                  type='email'
                  max={50}
                  placeholder="Employee's email"
                  onValueChange={handleChange}
                  value={String(formData?.email).replace(/[ ]/g, '').trim()}
                  required  
                  />             
              </div>
              <div className="modal-footer">
                <button type="button"
                  onClick={props.onClose}
                  className="btn btn-inactive"
                >Close</button>
                <BaseButton
                  loading={loading}
                  style={{ minWidth: 130 }}
                  type="submit"
                >
                  Send invite
                </BaseButton>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
}