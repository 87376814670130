/* eslint-disable react-hooks/exhaustive-deps */
import React, { RefObject, useEffect, useRef, useState } from "react"
import ThreeVerticalDotsIcon from "../../../../assets/icons/threeDots";
import ResendIcon from "../../../../assets/icons/resendIcon";
import TrashIcon from "../../../../assets/icons/trashIcon";
import { Pagination } from "../../../../components/pagination";
import UserIcon from "../../../../assets/icons/UserIcon";
import { EmployeeProps } from "../../../../includes/types";
import { GetRequest, PostRequest } from "../../../../includes/functions";
import { BaseLoader } from "../../../../components/baseloader";
import moment from "moment";
import { emitEvent, subscribeEvent, unsubscribeEvent } from "../../../../includes/customEventHook";
import { CONSTANTS } from "../../../../includes/constant";
interface InvitationTableProps {
  searchText?: string;
}
const InvitationTable = (props: InvitationTableProps) => {
  const [loadingItem, setLoadingItem] = useState<string>("");
  const [pageSize, setPageSize] = useState<number>(50);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [listOfEmployees, setListOfemployees] = useState<EmployeeProps[]>([])
  const [startDate, setStartDate] = useState<string>(moment().subtract(3, "M").toISOString());
  const [endDate, setEndDate] = useState<string>(moment().toISOString());
  const [fetching, setFetching] = useState(false);
  const [startSearchDate, setStartSearchDate] = useState<number>(0);
  const [endSearchDate, setEndSearchDate] = useState<number>(0);
 
  const GetInvitatedEmployees = (page: number) => {
    setFetching(true);
    GetRequest( `employees/pending`,
      {
        // page: page,
        // pageSize: pageSize,
        // startDate: moment(startDate).format("YYYY-MM-DD"),
        // endDate: moment(endDate).format("YYYY-MM-DD")
      },
       false).then((res) => {
      setFetching(false);
      if (res.status) {
        setListOfemployees(res?.data?.employees);
        emitEvent(CONSTANTS.Events.reloadEmployees,{
          invitationCount:res?.data?.employees.length
        })
      }
    })
  }

  const ResendInvitation = (v: EmployeeProps) => {
    setLoadingItem(v.email);
    PostRequest(`employee/invite/resend/${v._id}`, {
      email: v.email,
      lastName:v.lastName,
      firstName:v.firstName
    },true).then((res) => {
      setLoadingItem("");
    })
  }
  const CancelInvitation = (v: EmployeeProps) => {
    setLoadingItem(v.email);
    PostRequest(`employee/invite/cancel/${v._id}`, {}, true)
      .then((res) => {
        setLoadingItem("");
        if(res.status)
        {
        GetInvitatedEmployees(1);
        }
      })
  };
  useEffect(() => {
    subscribeEvent(CONSTANTS.Events.searchFilter, ({ detail }) => {
      if (detail?.startDate) {
    setStartSearchDate(parseInt(detail?.startDate));
    setEndSearchDate(parseInt(detail?.endDate));
      }
    });
    return () => {
      unsubscribeEvent(CONSTANTS.Events.searchFilter, () => {});
    };
  }, []);
  useEffect(() => {
    GetInvitatedEmployees(1);
    window.addEventListener("reloadEmployeeTable", (event: any) => {
      GetInvitatedEmployees(1);
    })
    window.addEventListener("reloadinvitedTable", (event: any) => {
      if (event.detail) {
        setEndDate(event.detail.endDate);
        setStartDate(event.detail.startDate);
        setTimeout(() => {
          GetInvitatedEmployees(currentPage);
        }, 1000)
      }
    });
  }, [])
  const Alldata = listOfEmployees.filter((a, i) =>
    String(a.firstName).toLowerCase().includes(String(props.searchText).toLowerCase()) ||
    String(a.id).toLowerCase().includes(String(props.searchText).toLowerCase()) ||
    String(a.email).toLowerCase().includes(String(props.searchText).toLowerCase())  || (parseInt(moment(a.createdAt).format("YYYYMMDD")) >= startSearchDate  &&  parseInt(moment(a.createdAt).format("YYYYMMDD")) <= endSearchDate))
  return <>
    <table className="table">
      <thead>
        <tr>
          <th scope="col">S. No.</th>
          <th scope="col">Employee ID</th>
          <th scope="col">Employee Name</th>
          <th scope="col">Email Address</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        {fetching && <tr >
          <td colSpan={10}>
            <BaseLoader /> 
            <small className='fs-small'>Fetching...</small>
          </td>
        </tr>}
        {Alldata.map((a, i) => <tr key={i}>
          <th scope="row">{(i + 1) > 9?(i + 1):"0"+(i + 1)}</th>
          <td>{a.employeeId}</td>
          <td>{a.firstName} {a.lastName}</td>
          <td>{a.email}</td>
          <td style={{ width: 50 }}>
            <div style={{ width: 50 }} className="d-flex align-items-center justify-content-center">
              {loadingItem === a.email ? <BaseLoader /> : <Menu
                onValue={(value) => {
                  if (value.action === "resend") {
                    ResendInvitation(a)
                  }
                  if (value.action === "cancel") {
                    CancelInvitation(a)
                  }
                }}
                type="data"
              >
                <ThreeVerticalDotsIcon />
              </Menu>}
            </div>
          </td>
        </tr>)}
      </tbody>
    </table>
    <Pagination
      onFilterRow={(d) => {
        setPageSize(d)
      }}
      onPage={(d) => {
        GetInvitatedEmployees(d);
        setCurrentPage(d);
      }}
    />
  </>
}
export default InvitationTable;

export interface MenuProps {
  children: JSX.Element;
  onValue: (d: { value: string; action: string; }) => void;
  type: "history" | "data" | "new"
  list?: ItemProps[]
}
export interface ItemProps {
  title: string;
  value: string;
  icon?: JSX.Element;
}
export const Menu = (props: MenuProps) => {
  const [show, setShow] = useState<boolean>(false);
  const thisDropDown = useRef() as RefObject<HTMLDivElement>;
  useEffect(() => {
    thisDropDown.current?.addEventListener("mouseleave", () => {
      setShow(false);
    })
  }, [])
  return <div 
  ref={thisDropDown}
  className="dropdown-wrp" >
    <button
      className="btn"
      onClick={() => {
        setShow(!show);
      }}
    >
   {props.children}
    </button>
    <div className="card dropdown-cnt"
      
    >
      {show && <ul >
        {props.type === "data" ? <>
          <li onClick={() => {
            props.onValue({ value: "resend invitation", action: "resend" });
            setShow(false);
          }} ><ResendIcon /> <span >Resend invitation</span></li>
          <li onClick={() => {
            props.onValue({ value: "cancel invitation", action: "cancel" });
            setShow(false);
          }}><TrashIcon /><span>Cancel invite</span></li>
        </> : null}

        {props.type === "history" ? <>
          <li onClick={() => {
            props.onValue({ value: "view", action: "view" });
            setShow(false);
          }}><ResendIcon /> <span >View details</span></li>
          <li onClick={() => {
            props.onValue({ value: "delete", action: "delete" });
            setShow(false);
          }}><TrashIcon /> <span >Delete</span></li>
        </> : null}

        {props.type === "new" ? <>
          <li onClick={() => {
            props.onValue({ value: "import", action: "import" });
            setShow(false);
          }}><ResendIcon /> <span >Import employee</span></li>
          <li onClick={() => {
            props.onValue({ value: "add", action: "add" });
            setShow(false);
          }}
          style={{width:165}}
          >
            <UserIcon />
            <span >Add single employee</span></li>
        </> : null}
      </ul>}
    </div>
  </div>
}
