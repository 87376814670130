import axios, {
  AxiosProgressEvent,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import { toast } from 'react-toastify';
import { CONSTANTS } from './constant';
export interface APIResponse {
  status: boolean;
  data: any;
  message: string;
  statusCode?: string;
}

export const PostRequest = (
  uri: string,
  data: any,
  success?: boolean,
  center?: boolean,
  fileType: 'json' | 'upload' = 'json'
) => {
  return new Promise<APIResponse>((resolve) => {
    const formdata = new FormData();
    Object.keys(data).forEach((ob, i) => {
      formdata.append(ob, data[ob]);
    });
    var getMethod = 'post';
    if (String(uri).includes(':')) {
      getMethod = String(uri).split(':')[0];
      uri = String(uri).split(':')[1];
    }
    const token = localStorage.getItem(CONSTANTS.LocalStorage.token);
    const RequestHeaders: any = {
      'Content-Type':
        fileType === 'json' ? 'application/json' : 'multipart/form-data',
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${token}`,
    };
    const options: AxiosRequestConfig = {
      headers: RequestHeaders,
      method: String(getMethod).toLowerCase(),
      data: fileType === 'json' ? data : formdata,
      url: `${CONSTANTS.BaseURL}${uri}`,
    };
    console.log('RequestHeaders:', options);
    axios(options)
      .then(({ data }: AxiosResponse) => {
        console.log(data);
        if (data.status) {
          if (success) {
            toast.success(data.message, {
              position: center
                ? toast.POSITION.TOP_CENTER
                : toast.POSITION.TOP_RIGHT,
            });
          }
          try {
            if (data.data.token) {
              localStorage.setItem(
                CONSTANTS.LocalStorage.token,
                data.data.token
              );
            }
          } catch (error) {}
          resolve(data);
        } else {
          toast.error(data.message, {
            position: center
              ? toast.POSITION.TOP_CENTER
              : toast.POSITION.TOP_RIGHT,
          });
          //  if(data.message.includes("malformed"))
          //  {
          //   localStorage.clear()
          //   window.location.href = "./";
          //  }
          resolve(data);
        }
      })
      .catch((error) => {
        console.log('error.response.data:', error);
        if (success) {
          toast.error(
            error?.response?.data
              ? error?.response?.data.message
              : error.message,
            {
              position: center
                ? toast.POSITION.TOP_CENTER
                : toast.POSITION.TOP_RIGHT,
            }
          );
        }
        resolve({
          status: false,
          message: error.message,
          data: {},
        });
      });
  });
};

export const GetRequest = (uri: string, data: any, success?: boolean) => {
  return new Promise<APIResponse>((resolve) => {
    var params: string[] = [];
    Object.keys(data).forEach((ob, i) => {
      params.push(`${ob}=${data[ob]}`);
    });
    const token = localStorage.getItem(CONSTANTS.LocalStorage.token);
    const RequestHeaders = {
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${token}`,
    };
    var options: AxiosRequestConfig = {
      headers: RequestHeaders,
      responseType: uri === 'vehicle/export' ? 'blob' : 'json',
    };
    axios
      .get(`${CONSTANTS.BaseURL}${uri}`, options)
      .then(({ data }: AxiosResponse) => {
        if (data.status) {
          if (success) {
            toast.success(data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
          resolve(data);
        } else {
          toast.error(data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          resolve(data);
        }
      })
      .catch((error) => {
        // console.log('error.response.data:', error);
        if (success) {
          toast.error(
            error?.response?.data
              ? error?.response?.data.message
              : error.message,
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        }
        resolve({
          status: false,
          message: error.message,
          data: {},
        });
      });
  });
};

export const PutEmployee = (
  id: string,
  data: any,
  success?: boolean,
  center?: boolean
) => {
  console.log('PutEmployee called with:', id, data);

  if (!id) {
    console.error('No ID provided');
    return Promise.reject(new Error('ID is required'));
  }

  const token = localStorage.getItem(CONSTANTS.LocalStorage.token);
  if (!token) {
    console.error('No token found');
    return Promise.reject(new Error('Token is required'));
  }

  const RequestHeaders = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
    Authorization: `Bearer ${token}`,
  };

  return new Promise<APIResponse>((resolve) => {
    axios
      .put(`${CONSTANTS.BaseURL}employee/${id}`, data, {
        headers: RequestHeaders,
      })
      .then(({ data }: AxiosResponse) => {
        if (data.status) {
          if (success) {
            toast.success(data.message, {
              position: center
                ? toast.POSITION.TOP_CENTER
                : toast.POSITION.TOP_RIGHT,
            });
          }
          resolve(data);
        } else {
          toast.error(data.message, {
            position: center
              ? toast.POSITION.TOP_CENTER
              : toast.POSITION.TOP_RIGHT,
          });
          resolve(data);
        }
      })
      .catch((error) => {
        console.log('Error in PutEmployee:', error);
        if (success) {
          toast.error(
            error?.response?.data
              ? error?.response?.data.message
              : error.message,
            {
              position: center
                ? toast.POSITION.TOP_CENTER
                : toast.POSITION.TOP_RIGHT,
            }
          );
        }
        resolve({ status: false, message: error.message, data: {} });
      });
  });
};

export const ValidateEmail = (value: string) => {
  const valid = value.match(
    /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  return valid;
};

export const ShowError = (message:string)=>{
  toast.error(message, {
    position:"center"
      ? toast.POSITION.TOP_CENTER
      : toast.POSITION.TOP_RIGHT,
  });
}
export const ShowSuccess = (message:string)=>{
  toast.success(message, {
    position:"center"
      ? toast.POSITION.TOP_CENTER
      : toast.POSITION.TOP_RIGHT,
  });
}
export const uploadImageToCloud = (file:File)=> {
 return  new Promise<APIResponse>((resolve)=>{
      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", "gearup");
      formData.append("cloud_name", "dhly0jxj2"); 
      const cloudinaryUrl = "https://api.cloudinary.com/v1_1/dhly0jxj2/image/upload";  // Replace 'your_cloud_name' with your actual cloud name
      fetch(cloudinaryUrl, {
          method: 'POST',
          body: formData
      })
      .then(response => response.json())
      .then(data => {
          console.log('Upload successful:', data,"Image URL:", data.secure_url);
          resolve({status:data.error?false:true,message:data.error?data.error.message:'Upload successful:.',data:data.error?"x":data.secure_url})
      })
      .catch(error => {
        console.log("Upload:",error);
          resolve({status:false,message:'Error uploading image:',data:null})
      });
  })
}
