import React, { ChangeEventHandler, useState } from 'react'
import './style.css';
import { EyeClose, EyeOpen } from '../../assets/icons/eye';
import { ValidateEmail } from '../../includes/functions';
import { BlockIcon } from '../../assets/icons/CloseIcon';
import { CaretDownIcon } from '../../screens/dashboard/icon';
interface BaseSelectInputProps {
  error?: any;
  id?: string;
  label?: string;
  disabled?: boolean;
  placeholder?: string;
  name: string;
  required?: boolean;
  value: any;
  pattern?: string;
  onValueChange:(d?:{ value: string; name: string; })=>void;
  options?: { value: string; name: string; }[];
}
export default function BaseSelectInput(props: BaseSelectInputProps) {
  const [show,setShow] = useState(false);
  return (<div className="mb-3 position-relative">
    <div className={`form-control radius-8`}
    onClick={()=>setShow(!show)}
    >
    <label className="form-label-x" >{props?.label}</label>
     <div >
     <input 
      className={"noborder"} 
      required={props.required}
      id={props.id} 
      onChange={()=>{
        setShow(true)
      }}
      disabled={show}
      name={props.name} 
      value={props.value}
      placeholder={props.placeholder}
      />
      </div>
       <span className='input-icon'
       style={{top:28}}
       >
        <CaretDownIcon  />
      </span>
   </div>
   {show && <ul className="dropdown-menu" style={{display:"block"}} aria-labelledby="dropdownMenuButton1">
    {props.options?.map((a,i,self)=><li key={i}
    onClick={()=>{
      props.onValueChange(a);
      setShow(false);
    }}
    className='px-3 fs-13 py-2'
    style={{cursor:"pointer",borderBottomWidth:self.length - 1 !== i?0.5:0,borderBottomColor:"#ddd",borderBottomStyle:"solid"}}
    >{a.name}</li>)}
  </ul>}
   </div>)
  // return <div className="mb-3 form-control"
  // onClick={()=>setShow(!show)}
  // >
  // {props?.label && <label htmlFor={props.name} className="form-label" style={{position:"relative"}}><small >{props?.label}</small></label>}
  //   <div 
  //   style={{height:40,borderRadius:8,position:"relative",cursor:"pointer"}}
  //   >
  //     <div style={{color:props.value?"black":"gray"}}>{props.value?props.value:props.placeholder}</div>
  //     <span className='input-icon'>
  //       <CaretDownIcon  />
  //     </span>
  // </div>
  
  // </div>
}

