/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useRef, useState } from 'react'
import { Logo } from '../../components/Logo'
import { LogoDesign } from '../../components/LogoDesign'
import { PoweredByComponent } from '../../components/PoweredBy'
import { CONSTANTS, passwordRules } from '../../includes/constant'
import { BaseButton, LightYellowButton } from '../../components/buttons'
import { Formik } from 'formik';
import * as y from 'yup';
import { GoBackIcon } from '../../assets/icons/BackIcon'

import OTPInput from 'react18-input-otp';
import { PostRequest } from '../../includes/functions'
import { useNavigate } from 'react-router-dom'
import BaseInput from '../../components/baseInput'
const schema = y.object({
  otp: y.string().required().max(6, "OTP must be 6 characters.").min(6, "OTP must be 6 characters."),
  password: y.string().required("Password is required.").matches(passwordRules, { message: 'Please create a stronger password.' }),
  confirmPassword:y.string().oneOf([y.ref('password')], 'Passwords must match')
})
export default function OTPScreen() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);
  const [resetloading, setResetLoading] = useState(false);
  const max: number = 30;
  const [timer, setTime] = useState<number>(max);
  const TimeCounter = useRef(null) as any;
  const CountDown = () => {

    TimeCounter.current = setInterval(() => {
      setTime((timer) => {
        if (timer === 0) {
          clearInterval(TimeCounter.current);
          return 0;
        }
        return timer - 1;
      });
    }, 1000)
  }

  const ResendOTP = () => {
    setResetLoading(true);
    setTime(max);
    PostRequest("auth/forgot-password", {
      email: localStorage.getItem(CONSTANTS.Routes.ForgotPassword)
    }, true).then((res) => {
      CountDown();
      setResetLoading(false);
    })
  }
  useEffect(() => {
    CountDown();
  }, [])
  return (<div className='row'>
    <div className='col-3 sidemenu position-relative' >
      <div className='p-5 ' >
        <Logo />
        <div className='sub'>
          Dare to move,<br />keep the record
        </div>
      </div>
      <div className='logo-wrapper' >
        <LogoDesign />
      </div>
    </div>
    <div className='col-9 p-5' >
      <Formik
        onSubmit={(values) => {
          setLoading(true);
          PostRequest("auth/reset-password", {
            otp:String(values.otp).trim(),
            password:String(values.confirmPassword).trim()
          }, true).then((res) => {
            setLoading(false);
            if (res.status) {
              localStorage.setItem("token", res.data.temptoken);
              localStorage.setItem(CONSTANTS.Routes.Join, String(values.email));
              navigate(CONSTANTS.Routes.Login);
            }
          })
        }}
        validationSchema={schema}
        initialValues={{
          otp: "",
          password:"",
          confirmPassword:"",
          email: localStorage.getItem(CONSTANTS.Routes.ForgotPassword)
        }}
      >
        {({ handleSubmit,errors, handleChange, setFieldValue, values }) => <div className='ps-5' >
          <div className='pb-5'>
            <LightYellowButton
              to={CONSTANTS.Routes.Login}
            >
              <GoBackIcon
                color="#F8B02B"
                size={15}
              />
              <span className='ps-2'>Go back</span>
            </LightYellowButton>
          </div>
          <div className="text-start title-text">OTP Verification</div>
          <div className="text-start">Please enter your new password and the OTP we sent to your email address to proceed</div>
          <div className='row p-5 ps-0' >
            <div className='col-8' >
              <div className='text-center d-flex justify-content-center align-items-center' >
                <OTPInput
                
                  value={values.otp}
                  onChange={(enteredOtp: string) => {
                    setFieldValue("otp", enteredOtp);
                  }}
                  numInputs={6}
                  inputStyle={{
                    display: "block",
                    width: "100%",
                    paddingVertical: 15,
                    paddingHorizontal: 0.75,
                    fontSize: 16,
                    fontWeight: "bold",
                    height: 40,
                    color: "#212529",
                    backgroundColor: "#fff",
                    borderWidth: 1,
                    borderStyle: "solid",
                    borderColor: "#ced4da",
                    borderRadius: 8,
                    transition: "border-color .15s ease-in-out",
                    boxShadow: ".15s ease-in-out"
                  }}
                  separator={<span style={{ width: 10 }}></span>}
                />
                </div>
                <div className='pt-3'>
                <BaseInput
                name='password'
                type='password'
                max={30}
                label='Password'
                placeholder='Enter new password'
                onValueChange={handleChange("password")}
                value={values.password}
                required={true}
                error={errors.password}
              />
                <BaseInput
                label='Confirm password'
                name='password'
                type='password'
                max={30}
                placeholder='Enter confirm password'
                onValueChange={handleChange("confirmPassword")}
                value={values.confirmPassword}
                required={true}
                error={errors.confirmPassword}
              />
              </div>
              <div className='text-center mb-5'>
                <span className='timer-text1'>I have not received it  <span className='timer-text2' onClick={() => {
                  if (timer === 0) {
                    ResendOTP();
                  }
                }}>Resend OTP {timer}</span></span>
              </div>
              <div className='row' >
              <BaseButton
                onClick={handleSubmit}
                loading={loading}
                style={{ marginTop: 10 }}
              >Verify</BaseButton>
            </div>
            </div>
            <div className='col-2' ></div>
          </div>
        </div>}
      </Formik>
      <span className='poweredby'>
        <PoweredByComponent />
      </span>

    </div>
  </div>
  )
}
