import React, { FormEvent, useEffect, useState } from 'react'
import { Logo } from '../../components/Logo'
import { LogoDesign } from '../../components/LogoDesign'
import { PoweredByComponent } from '../../components/PoweredBy'
import BaseInput from '../../components/baseInput'
import { useNavigate, useSearchParams} from 'react-router-dom'
import { CONSTANTS } from '../../includes/constant'
import { BaseButton } from '../../components/buttons'
import { GetRequest, PostRequest } from '../../includes/functions'
import BaseSelectInput from '../../components/baseSelectInput'

interface CompanyDetailsProp {
  name:string;
  email:string;
  lastName?:string;
  firstName?:string;
  ref?:string;
  roleInOrganization?:string;
  password?:string;
  terms?:boolean;
}
export default function CreatePasswordScreen() {
  const [loading,setLoading] = useState(false);
  const navigate = useNavigate();
  const [refOtp,setRefOtp] = useState<string>("");
  const [showSuccess,setShowSuccess] = useState(false);
  const [details,setDetails] = useState<CompanyDetailsProp>({
    firstName:"",
    lastName:"",
    email:"",
    name:""
  });
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(()=>{
  const refParam = searchParams.get("ref");
  if(refParam)
  {
  setRefOtp(refParam);
GetRequest("business?ref="+refParam,{}).then((res)=>{
  console.log(res);
  if(res.status)
  {
    setDetails(res.data.business);
  }else{
    navigate("/");
  }
})
  }
  },[searchParams])
  const handleSubmit = (e:FormEvent)=>{
    e.preventDefault();
      setLoading(true);
      PostRequest("auth/invite",{
        password:details.password,
        roleInOrganization:details.roleInOrganization,
        ref:refOtp,
        firstName:details.firstName,
        lastName:details.lastName,
      },true).then((res)=>{
        setLoading(false);
        if(res.status)
        {
          navigate(CONSTANTS.Routes.Login,{replace:true});
        }
       })
  }
  return (<div className='row'>
     <div className='col-3 sidemenu position-relative' >
     <div className='p-5 ' >
        <Logo />
        <div className='sub'>
        Dare to move,<br/>keep the record
        </div>
        </div>
        <div className='logo-wrapper' >
        <LogoDesign />
        </div>
     </div>
     <div className='col-9 p-5' style={{overflow:"scroll",height:"100vh"}} >
     <form
       onSubmit={handleSubmit}
      >
     <div className='ps-5' >
        <div className="text-start title-text">Let's go!</div>
        <div className="text-start">Create a password to proceed.</div>
        <div className='row p-5 ps-0' >
        <div className='col-8 p-3' >
        <BaseInput 
        label='First Name'
        name='firstName'
        type='text'
        max={50}
        placeholder='Enter your First Name'
        onValueChange={(d)=>{
          setDetails({...details,firstName:d.target.value});
        }}  
        value={details.firstName}
        required
        />
        <BaseInput 
        label='Last Name'
        name='lastName'
        type='text'
        max={50}
        placeholder='Enter your Last name'
        onValueChange={(d)=>{
          setDetails({...details,lastName:d.target.value});
        }}  
        value={details.lastName}
        required
        />
        <BaseInput 
        label='Work email address'
        name='workEmail'
        type='text'
        max={50}
        disabled
        placeholder='Work email address'
        onValueChange={(d)=>{
          return
        }}  
        value={details.email}
        required
        />
        <BaseSelectInput
                    label="Role In Organization"
                    name="roleInOrganization"
                    placeholder="Enter role"
                    onValueChange={(value)=>{
                      setDetails({...details,roleInOrganization:value?.value});
                    }}
                    value={details.roleInOrganization}
                    required={true}
                    options={[
                      {
                        name:"HR Manager",value:"HR Manager"
                      },
                      {
                        name:"Operations Manager",value:"Operations Manager"
                      }
                      ,
                      {
                        name:"Office Manager",value:"Office Manager"
                      },
                      {
                        name:"Account Officer",value:"Account Officer"
                      },
                      {
                        name:"Others",value:"Others"
                      }
                    ].sort(function (a, b) {
                      if (a.name < b.name) {
                        return -1;
                      }
                      if (a.name > b.name) {
                        return 1;
                      }
                      return 0;
                    })}
                  />
       <BaseInput 
       label='Password'
        name='Password'
        type='password'
        max={100}
        placeholder='Enter your password'
        onValueChange={(d)=>{
          setDetails({...details,password:String(d.target.value).replace(/[ ]/g,'')});
        }}  
        value={details.password}
        />
        <div className="form-check">
        <input className="form-check-input" 
        required
        type="checkbox" 
        value={details.terms?1:0} 
        id="terms"
        onChange={(d)=>{
        setDetails({...details,terms:!details.terms});
        }}
         />
        <label className="form-check-label" htmlFor="terms" >
        By checking the box, you agree to our  <a href={"https://www.treepz.com/terms"}>Terms of use & Privacy policy</a>
        </label>
        </div>
        <div className='row p-2 pe-3' >
        <BaseButton 
        type='submit'
        loading={loading}
        style={{marginTop:30}}
        >Join</BaseButton>
        </div>
        </div>
        <div className='col-2' ></div>
        </div>
   </div>
    </form>
        <span className='poweredby'>
            <PoweredByComponent />
        </span>
     </div>
    </div>
  )
}
