import React, { useEffect, useState } from 'react'
import './../style.css';
import { SearchBar } from '../components/searchBar';
import { AddPersonnelComponent } from '../components/addPersonnel';
import { ImportPersonnelComponent } from '../components/importPersonnel';
import EmployeesTable from './HistoryTables/employees';
import InvitationTable from './HistoryTables/invitations';
import { EmployeeProps } from '../../../includes/types';
import { toast } from 'react-toastify';
import { DownloadCSV } from '../../../includes/createCSVFileHook';
import {
  emitEvent,
  subscribeEvent,
  unsubscribeEvent,
} from '../../../includes/customEventHook';
import { CONSTANTS } from '../../../includes/constant';
import moment from 'moment';
export default function EmployeeDataSection() {
  const [tab, setTab] = useState<string>("employee")
  const [processing, setProcessing] = useState<boolean>(false)
  const [showAddPersonnel, setShowAddPersonnel] = useState<boolean>(false)
  const [showImportPersonnel, setShowImportPersonnel] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const [listOfEmployees, setListOfemployees] = useState<EmployeeProps[]>([])
  const [numberOfPending, setNumberOfPending] = useState<number>(0);
  const [numberOfActive, setNumberOfActive] = useState<number>(0);

  const handleExport = () => {
    DownloadCSV({ data: listOfEmployees, fileName: "Employees_list" });
    toast.success("Download was successful.", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  useEffect(() => {
    subscribeEvent(CONSTANTS.Events.reloadEmployees, ({ detail }) => {
      if (detail?.employeesCount) {
        setNumberOfActive(detail?.employeesCount);
      }
      if (detail?.invitationCount) {
      setNumberOfPending(detail?.invitationCount);
      }
    });
    return () => {
      unsubscribeEvent(CONSTANTS.Events.reloadEmployees, () => {});
    };
  }, [tab]);
  return <div className='main-scrollable p-5 pt-0' >
    <div className="heading mb-3" >
      <b className='fCap'>Treepz Data</b></div>
    <SearchBar
      onSearch={(d) => {
        setSearchText(d);
      }}
      page='data'
      onAddPersonnel={() => {
        setShowAddPersonnel(true);
      }}
      processing={processing}
      onExportPersonnel={() => {
        handleExport();
      }}
      onImportPersonnel={() => {
        setShowImportPersonnel(true)
      }}
      showFilter={true}
      onFilterValue={({ startDate, endDate }) => {
        emitEvent(CONSTANTS.Events.searchFilter,{
          startDate:moment(startDate).format("YYYYMMDD"),
          endDate:moment(endDate).format("YYYYMMDD")
        })
        // const sendData = { startDate, endDate }
        // const reloadEvent = new CustomEvent(tab === "employee" ? "reloadEmployeeTable" : "reloadinvitedTable", { detail: sendData });
        // window.dispatchEvent(reloadEvent);
      }}
    />

    <nav className="nav pl-c">
      <span
        onClick={() => {
          setTab("employee");
          setSearchText("")
        }}
        className={`nav-link active btn p-2 mb-2 ${tab === "employee" ? "fw-bold selected-tab" : "fw-normal"}`} aria-current="page" >
        Employees <span className={tab === 'employee' ?'tab-count-active':'tab-count-inactive'}>{numberOfActive}</span>
      </span>
      <span
        onClick={() => {
          setSearchText("")
          setTab("invited")
        }}
        className={`nav-link btn p-2 mb-2 ${tab === "invited" ? "fw-bold selected-tab" : "fw-normal"}`} >
          Invited members <span className={tab === 'invited' ?'tab-count-active':'tab-count-inactive'}>{numberOfPending}</span>
        </span>
    </nav>

    {tab === "employee" ? (<EmployeesTable
      searchText={searchText}
      onAddNewBusiness={() => setShowAddPersonnel(true)}
    /> 
    ): null}

    {tab === "invited" ?
      <InvitationTable 
      searchText={searchText} /> : null}
    {showAddPersonnel && (
      <AddPersonnelComponent onClose={() => 
        setShowAddPersonnel(false)}
    />)}
    
    {showImportPersonnel && (
      <ImportPersonnelComponent
      onClose={() => setShowImportPersonnel(false)}
    />)}
  </div>
}
