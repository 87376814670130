import React, { useEffect, useState } from "react";
import './style.css';

const MobilePopup = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    // Check if the device is mobile
    const isMobileDevice = window.innerWidth <= 800 || /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    setIsMobile(isMobileDevice);

    // Show the popup only if it's a mobile device and hasn't been shown before
    if (isMobileDevice && !localStorage.getItem("mobilePopupDisplayed")) {
      setShowPopup(true);
    }
  }, []);

  const handleClose = () => {
    setShowPopup(false);
    localStorage.setItem("mobilePopupDisplayed", "true");
  };

  if (!isMobile || !showPopup) return null;

  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={handleClose}>&times;</span>
        <h2>Welcome to Our Web Application!</h2>
        <p>This site is best viewed on a larger screen, but feel free to continue browsing on your mobile device.</p>
      </div>
    </div>
  );
};

export default MobilePopup;
