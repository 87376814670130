export const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#\$%\^&\*]).{8,20}$/;
// api.admin.treepz.com api.business.treepz.com api.rider.treepz.com
export const CONSTANTS = {
    BaseURL:'https://orca-app-sgkzf.ondigitalocean.app/v1/',
    Routes:{
      Login:"/login",
      Otp:"/otp",
      ForgotPassword:"/forgot_password",
      Dashboard:"dashboard",
      TreepzHistory:"treepz-history",
      EmployeeData:"employee-data",
      Join:"/create_password",
      Settings:"settings",
      Notification:"notification",
    },
    LocalStorage:{
      token:"businessToken"
    },
    Events:{
      reloadEmployees:"reloadEmployees",
      searchFilter:"searchFilter"
    }
}

interface ListProps {
icon?:HTMLElement;
title:string;
link:string;
}

export const DashboardNavItems:ListProps[] = [
  {title:"Dashboard",link:"/"+CONSTANTS.Routes.Dashboard},
  {title:"Treepz History",link:"/"+CONSTANTS.Routes.Dashboard+"/"+CONSTANTS.Routes.TreepzHistory},
  {title:"Employee Data",link:"/"+CONSTANTS.Routes.Dashboard+"/"+CONSTANTS.Routes.EmployeeData},
] 