/* eslint-disable react-hooks/exhaustive-deps */
import React, { FormEvent, RefObject, useEffect, useRef, useState } from 'react';
import './../style.css';
import { GetRequest, PostRequest, ShowError, uploadImageToCloud } from '../../../includes/functions';
import { BaseButton } from '../../../components/buttons';
import { Formik, FormikProps, FormikValues } from 'formik';
import * as y from 'yup';
import BaseInput from '../../../components/baseInput';
import { UserProps } from '../../../includes/types';
import { CONSTANTS, passwordRules } from '../../../includes/constant';
import UploadIcon from '../../../assets/UploadIcon';
import CloudIcon from '../../../assets/icons/cloudIcon';
import Dropzone from 'react-dropzone';
import DotIcon from '../../../assets/icons/dot';
import { BaseLoader } from '../../../components/baseloader';
import ImageIcon from '../../../assets/icons/imageIcon';
import { toast } from 'react-toastify';
import TransIcon from '../../../assets/icons/transIcon';
import SucceesModal from '../components/success';
import { useNavigate } from 'react-router-dom';

const schema = y.object({
  newPassword:y
  .string()
  .required('New Password is required.')
  .matches(passwordRules, { message: 'Please create a stronger password.' }),
  oldPassword:y
  .string()
  .required('Current Password is required.')});


export default function SettingsSection() {
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState<string>('profile');
  const [searchText, setSearchText] = useState<string>('');
  const [user, setUser] = useState<UserProps>({});
  const [logo, setLogo] = useState<string | null>(null);
  const [gracePeriod, setGracePeriod] = useState<number | null>(null);
  const [autoCheckInOut, setAutoCheckInOut] = useState(false);
  const thisForm = useRef() as RefObject<FormikProps<FormikValues>>;

  const GetUserInfo = () => {
    GetRequest('user', {})
      .then((response) => {
        if (response.status) {
          console.log('API response for user: ', response);
          setUser(response.data);
        }
      })
      .catch((error) => {
        console.error('Error fetching user data:', error);
      });

    const storedEmail = localStorage.getItem(CONSTANTS.Routes.Login);
    const storedFullname = localStorage.getItem('fullname');

    if (storedEmail || storedFullname) {
      setUser((prevUser) => ({
        ...prevUser,
        email: storedEmail || prevUser.email,
        ...JSON.parse(storedFullname || '{}'),
      }));

      if (storedFullname) {
        const parsedFullname = JSON.parse(storedFullname);
        thisForm.current?.setFieldValue('firstName', parsedFullname.firstName);
        thisForm.current?.setFieldValue('lastName', parsedFullname.lastName);
      }
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Handle form submission logic here
  };

  const handleGracePeriodChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setGracePeriod(Number(event.target.value));
  };

  const handleLogoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        setLogo(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAutoCheckInOutToggle = () => {
    setAutoCheckInOut(!autoCheckInOut);
  };
  // const saveTransportSettings = (values: any) => {
  //     setLoading(true);
  //     const transportData = {
  //         autoCheckInOut,
  //         gracePeriod
  //     };
  //     PostRequest('put:admin/transport-settings', transportData, true)
  //         .then((response) => {
  //             if (response.status) {
  //                 console.log(response.data);
  //             }
  //         })
  //         .catch((error) => {
  //             console.error(error);
  //         });

  // };

  useEffect(() => {
    // GetUserInfo();
  }, []);

  function setFieldValue(arg0: string, value: string): void {
    throw new Error('Function not implemented.');
  }

  return (
    <div className="main-scrollable p-5 pt-0">
      <div className="heading mb-3">
        <b className="fCap">My Settings</b>
      </div>
      <div className="d-flex align-items-center justify-content-start tabContainer"></div>

      <nav className="nav pl-c">
        <span
          onClick={() => {
            setTab('profile');
            setSearchText('');
          }}
          className={`nav-link active btn p-2 mb-2 ${
            tab === 'profile' ? 'fw-bold selected-tab' : 'fw-normal'
          }`}
          aria-current="page"
        >
          Profile
        </span>
        <span
          onClick={() => {
            setSearchText('');
            setTab('business details');
          }}
          className={`nav-link btn p-2 mb-2 ${
            tab === 'business details' ? 'fw-bold selected-tab' : 'fw-normal'
          }`}
        >
          Business Details
        </span>
        {/* <span
          onClick={() => {
            setSearchText('');
            setTab('notification');
          }}
          className={`nav-link btn p-2 mb-2 ${
            tab === 'notification' ? 'fw-bold selected-tab' : 'fw-normal'
          }`}
        >
          Notification
        </span> */}
        <span
          onClick={() => {
            setSearchText('');
            setTab('transport schedule management');
          }}
          className={`nav-link btn p-2 mb-2 ${
            tab === 'transport schedule management'
              ? 'fw-bold selected-tab'
              : 'fw-normal'
          }`}
        >
          Transport Schedule Management
        </span>
      </nav>

      {tab === 'profile' ? (
        <ProfileComponent />
      ) : tab === 'business details' ? (
        <BusinessDetails handleSubmit={handleSubmit} />
      ) : tab === 'notification' ? (
        <NotificationComponent />
      ) : tab === 'transport schedule management' ? (
        <TransportScheduleManagement />
      ) : (
        <h5>Other tabs content goes here...</h5>
      )}
    </div>
  );
}

const ProfileComponent: React.FC = () => {
const [showSuccess,setShowSuccess] = useState<boolean>(false)
  const [loading2, setLoading2] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<UserProps>({});
  const [logo, setLogo] = useState<string | null>(null);
const thisForm = useRef() as RefObject<FormikProps<FormikValues>>;
const handleProfileUpdate = (e:FormEvent)=>{
e.preventDefault();
setLoading(true);
var data:any = {}
if(user?.adminFirstName)
{
  data.adminFirstName = user?.adminFirstName;
}
if(user?.adminLastName)
  {
    data.adminLastName = user?.adminLastName;
  }
PostRequest('put:business',data, true)
  .then((response) => {
    setLoading(false);
  })
}

const handlePaswordUpdate = (e:FormEvent)=>{
  e.preventDefault()
  setLoading2(true);
  PostRequest('auth/change-password', {
    oldPassword:user.oldPassword,
    newPassword:user.newPassword
  }, true)
    .then((response) => {
      setLoading2(false);
    setShowSuccess(response.status);

    })
  }
 
  const GetUserInfo = () => {
    GetRequest('business/detail', {})
      .then((response) => {
        if (response.status) {
          setUser(response.data.business);
        }
      })
    }

  const handleLogoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        setLogo(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };
const navigate = useNavigate();
  useEffect(() => {
    GetUserInfo();
  }, []);

  return (
    <div className="mt-3 w={40} h={40}">
     
          <div className='row setting-profile'>
            <div className="col-1">
              <center>
                {logo ? (
                  <img src={logo} alt="User Logo" className="avatar" />
                ) : (
                  <div className="avatar d-flex align-items-center justify-content-center position-relative">
                    <span className="fs-2 fw-bold orange-text">
                      {[String(user?.firstName), String(user?.lastName)]
                        .map((a) => a[0])
                        .join('')}
                    </span>
                    <span
                      className="upload-icon position-absolute"
                      style={{
                        padding: '7px',
                        borderRadius: '19px',
                        border: '1px solid #FFFFFF',
                        right: '-5px',
                        bottom: '4px',
                        backgroundColor: 'var(--Colour-Gray-Gray-95, #EFF0F0)',
                        cursor: 'pointer',
                      }}
                      onClick={() =>
                        document.getElementById('logo-upload')?.click()
                      }
                    >
                      <ImageIcon />
                    </span>
                  </div>
                )}
                <b>
                  {user.firstName} {user.lastName}
                </b>
                <input
                  id="logo-upload"
                  type="file"
                  accept="image/*"
                  onChange={handleLogoChange}
                  className="d-none"
                />
              </center>
            </div>
            <div className="col-6">
            <form
       onSubmit={handleProfileUpdate}
      >
              <div>
                <small>First name</small>
              </div>
              <BaseInput
                name="firstName"
                type="text"
                placeholder="First name"
                max={20}
                onValueChange={(d) =>
                  setUser({...user,adminFirstName:d.target.value})
                }
                value={user.adminFirstName}
                required
              />
              <div>
                <small>Last name</small>
              </div>
              <BaseInput
                name="lastName"
                type="text"
                placeholder="Last name"
                max={20}
                onValueChange={(d) =>
                  setUser({...user,adminLastName:d.target.value})
                }
                value={user.adminLastName}
                required
              />
              <div>
                <small>Email address</small>
              </div>
              <BaseInput
                disabled
                name="email"
                type="email"
                placeholder="Email address"
                max={50}
                onValueChange={() => {}}
                value={user.email}
                required
              />
               <div className="d-flex justify-content-end mb-5">
                <BaseButton 
                type='submit'
                 loading={loading}>
                  Save changes
                </BaseButton>
              </div>
              </form>
              <form
              onSubmit={handlePaswordUpdate}
              >
              <div className='fs-8 mb-3'>
                <b>Change password</b>
              </div>
              <div>
                <small>Current Password</small>
              </div>
              <BaseInput
                name="password"
                type="password"
                placeholder="Enter current password"
                max={20}
                onValueChange={(d) =>
                  setUser({...user,oldPassword:d.target.value})
                }
                value={user.oldPassword}
                required
              />
               <div>
                <small>New Password</small>
              </div>
              <BaseInput
                name="password"
                type="password"
                placeholder="Enter new password"
                max={20}
                onValueChange={(d) =>
                  setUser({...user,newPassword:d.target.value})
                }
                value={user.newPassword}
                required
              />
              <div className="d-flex justify-content-end">
                <BaseButton 
                type='submit'
                 loading={loading2}>
                  Save changes
                </BaseButton>
              </div>
              </form>
            </div>
            <div className="col-2"></div>
          </div>
       {showSuccess && <SucceesModal
       onClose={()=>{
        localStorage.clear();
        navigate("/");
       }}
       />}  
    </div>
  );
};

const NotificationComponent: React.FC = () => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [unreadCount, setUnreadCount] = useState<number>(0);
  const [loading, setLoading] = useState(false);

  interface NotificationSetting {
    label: string;
    type: keyof NotificationSettings;
  }

  interface NotificationSettings {
    newBusiness: string;
    newAdmin: string;
    employeeSignup: string;
    newUserCheckIn: string;
    newUserCheckOut: string;
  }

  const [notificationSettings, setNotificationSettings] =
    useState<NotificationSettings>({
      newBusiness: 'None',
      newAdmin: 'None',
      employeeSignup: 'None',
      newUserCheckIn: 'None',
      newUserCheckOut: 'None',
    });

    const saveNotificationSettings = async (settings: NotificationSettings) => {
      try {
        const response = await PostRequest('patch:admin/notification-settings', settings, true);
        if (response.status) {
          toast.success('Notification settings saved successfully.', {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      } catch (error) {
        console.error('Error saving notification settings:', error);
        toast.error('Failed to save notification settings.', {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    };

    const handleNotificationChange = (
      event: React.ChangeEvent<HTMLInputElement>,
      type: keyof NotificationSettings
    ) => {
      const updatedSettings = {
        ...notificationSettings,
        [type]: event.target.value,
      };
  
      setNotificationSettings(updatedSettings);
      saveNotificationSettings(updatedSettings);
    };

  const generalNotifications: NotificationSetting[] = [
    { label: 'When a new business joins', type: 'newBusiness' },
    { label: 'When a new admin joins', type: 'newAdmin' },
    { label: 'When an employee signs up', type: 'employeeSignup' },
  ];

  const activityNotifications: NotificationSetting[] = [
    { label: 'When new user checks in', type: 'newUserCheckIn' },
    { label: 'When new user checks out', type: 'newUserCheckOut' },
  ];

  const NotificationOption = ({ label, type }: NotificationSetting) => (
    <>
    <div className="notification-option mb-4 row align-items-center">
      <div className="col-4">
        <div className="label-text" style={{ marginBottom: '16px' }}>{label}</div>
      </div>

      <div className="col-8">
        <div className="radio-group" style={{ marginTop: '16px', marginLeft: '60%'}}>
          <label className="radio-box" data-text="None">
            <input
              type="radio"
              name={type}
              value="None"
              checked={notificationSettings[type] === 'None'}
              onChange={(e) => handleNotificationChange(e, type)}
            />
            <span className="box">None</span>
          </label>
          <label className="radio-box" data-text="In-app">
            <input
              type="radio"
              name={type}
              value="In-app"
              checked={notificationSettings[type] === 'In-app'}
              onChange={(e) => handleNotificationChange(e, type)}
            />
            <span className="box">In-app</span>
          </label>
          <label className="radio-box" data-text="Email">
            <input
              type="radio"
              name={type}
              value="Email"
              checked={notificationSettings[type] === 'Email'}
              onChange={(e) => handleNotificationChange(e, type)}
            />
            <span className="box">Email</span>
          </label>
        </div>
      </div>
    </div>
    <hr />
    </>
  );

  return (
    <div className='mt-4 gap-9'>
      <div className="body-content d-flex">
        <div className='col-3' style={{ marginRight: '16px' }}>
          <h5>General Notifications</h5>
          <p className="body-text">
            Select when to be notified when the following changes occur
          </p>
        </div>
        <div className='col-8'>
          {generalNotifications.map((notification) => (
            <NotificationOption key={notification.type} 
            
            {...notification} />
          ))}
        </div>
      </div>

      <div className="body-content d-flex">
        <div className='col-3' style={{ marginRight: '16px' }}>
          <h5>Activity Notifications</h5>
          <p className="body-text">
            Select when to be notified when the following changes occur
          </p>
        </div>
        <div className='col-8'>
          {activityNotifications.map((notification) => (
            <NotificationOption key={notification.type} {...notification} />
          ))}
        </div>
      </div>
    </div>
  );
};

const TransportScheduleManagement: React.FC = () => {
  const [morningCheckInTime, setMorningCheckInTime] = useState('');
  const [eveningCheckOutTime, setEveningCheckOutTime] = useState('');
  const [gracePeriod, setGracePeriod] = useState(20);
  const [autoCheckInOut, setAutoCheckInOut] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleGracePeriodChange: React.ChangeEventHandler<HTMLSelectElement> = (
    e
  ) => {
    const value = e.target.value;
    setGracePeriod(Number(value));
  };

  const handleMorningCheckInTimeChange: React.ChangeEventHandler<
    HTMLInputElement
  > = (e) => {
    setMorningCheckInTime(e.target.value);
  };

  const handleEveningCheckOutTimeChange: React.ChangeEventHandler<
    HTMLInputElement
  > = (e) => {
    setEveningCheckOutTime(e.target.value);
  };
const BusinessDetails = ()=>{
  GetRequest('business/detail', {})
  .then((response) => {
    if (response.status) {
      if(response?.data?.business)
      {
        const {checkInTime,checkOutTime,gracePeriod} = response?.data?.business;
        setEveningCheckOutTime(checkOutTime);
        setMorningCheckInTime(checkInTime);
        setGracePeriod(Number(gracePeriod));
      }
    }
  })
  .catch((error) => {
    console.error('Error fetching user data:', error);
  });
}
  const saveTransportSettings = async () => {
    try {
      const data = {
        checkInTime: morningCheckInTime,
        checkOutTime: eveningCheckOutTime,
        gracePeriod: gracePeriod,
        autoCheckInOut: autoCheckInOut
      };
      if(parseInt(String(data.checkInTime).replace(":","")) > 1200)
      {
        return ShowError("Check Time must be AM");
      }
      if(parseInt(String(data.checkOutTime).replace(":","")) < 1201)
        {
          return ShowError("Check Time must be PM");
        }
      setLoading(true);
      const response = await PostRequest('put:business', data, true);
      console.log('Settings saved:', response.data);
    } catch (error) {
      console.error('Error saving transport settings:', error);
    } finally {
      setLoading(false);
    }
  };
useEffect(()=>{
  BusinessDetails();
},[])
  return (
    <div className="transport-management-container p-2">
      <div className="transport-top align-items-center mb-4">
        <TransIcon />
        <p>
          Set a time to notify your employees to check-in/out for their
          daily/weekly transport. If an employee does not manually check-in/out
          within the time you have set ± the grace period, they will be
          checked-in/out automatically.
        </p>
      </div>
      <div className="transport-check">
        <div className="transport-check-content">
          <div>
            <p>Morning check-in time</p>
            <input
              type="time"
              className="transport-box"
              value={morningCheckInTime}
              onChange={handleMorningCheckInTimeChange}
            />
          </div>
          <div>
            <p>Evening check-out time</p>
            <input
              type="time"
              className="transport-box"
              value={eveningCheckOutTime}
              onChange={handleEveningCheckOutTimeChange}
            />
          </div>
        </div>
      </div>
      <div className="mb-3 pt-3">
        <label className="form-label">Grace Period (minutes)</label>
        <select
          value={gracePeriod}
          onChange={handleGracePeriodChange}
          className="form-select"
        >
          <option value="">Select Grace Period</option>
          <option value={20}>20 minutes</option>
          <option value={30}>30 minutes</option>
          <option value={45}>45 minutes</option>
        </select>
      </div>
      {/* <div className="form-check form-switch mb-3">
        <input
          className="form-check-input"
          type="checkbox"
          id="autoCheckInOut"
          checked={autoCheckInOut}
          onChange={handleAutoCheckInOutToggle}
        />
        <label className="form-check-label bold-label" htmlFor="autoCheckInOut">
          Enable Auto check-in/check-out with grace period
        </label>
      </div> */}
      <div className="pe-3">
        <BaseButton onClick={saveTransportSettings} loading={loading}>
          Save changes
        </BaseButton>
      </div>
    </div>
  );
};

interface BusinessDetailsProps {
  handleSubmit: (e: React.FormEvent) => void; // Define the prop type for handleSubmit
}
interface BusinessProfileProps {
  image?:string;
  name?:string;
  checkInTime?:string;
  checkOutTime?:string;
  gracePeriod?:number;
  autoCheckInOut?: boolean;
  city?:string;
  country?:string;
}
interface CountryProps {
name:string;
cities:string[];
}
interface CityProps {

}
const BusinessDetails: React.FC<BusinessDetailsProps> = ({ handleSubmit }) => {
  const [logo, setLogo] = useState<string | null>(null);
  const [uploading, setUploading] = useState<boolean>(false);
  const [bussinessInfo, setBussinessInfo] = useState<BusinessProfileProps>({});
  const [formData, setFormData] = useState<BusinessProfileProps>({
    country:"Nigeria"
  });
  const [fileName, setFileName] = useState('No selected file');
  const [loading, setLoading] = useState(false);
  const [logoUploading, setLogoUploading] = useState(false);

  const handleLogoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
        setFileName(file.name);
        const reader = new FileReader();
        reader.onload = (e) => {
          setLogo(reader.result as string);
          // const base64String = e.target.result; // This is the base64 encoded string
          setLogoUploading(true);
          uploadImageToCloud(file).then((res)=>{
        if(!res.status)
        {
          setLogoUploading(false);
          return ShowError(res.message)
        }
          const data = {
            image: res.data
          };
          PostRequest('put:business', data, true).then((res)=>{
            setLogoUploading(false);
          })
        })
        };
        reader.readAsDataURL(file);
      } else {
        console.error('Please upload an image file.');
        setLogoUploading(false);
        toast.error('Please upload a valid image file.', {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }

const handleSubmitForm = (e:FormEvent)=>{
  e.preventDefault();
  setLoading(true);
  PostRequest('put:business', formData, true).then((res)=>{
    setLoading(false);
  })
}

const listOfCountry:CountryProps[] = [
  {name:"Nigeria",
    cities:[
      "Abuja", "Kano", "Kaduna", "Maiduguri", "Sokoto", "Minna", "Jos", "Zaria", "Katsina", "Birnin Kebbi",
      "Lagos", "Ibadan", "Abeokuta", "Osogbo", "Akure", "Ilorin", "Benin City", "Oyo", "Shagamu",
      "Port Harcourt", "Calabar", "Uyo", "Warri", "Enugu", "Onitsha", "Aba", "Owerri", "Umuahia",
      "Abakaliki", "Ile-Ife", "Sapele", "Lokoja", "Makurdi", "Ifo", "Jalingo", "Gombe", "Yola", "Kogi",
      "Kano", "Bauchi", "Sokoto", "Kaduna", "Ilorin", "Akure", "Abeokuta"
  ]},
  {name:"Ghana",
    cities: [
      "Accra", "Kumasi", "Takoradi", "Tamale", "Ashaiman", "Sekondi", "Cape Coast", "Koforidua", 
      "Sunyani", "Ho", "Tema", "Mampong", "Techiman", "Akim Oda", "Tarkwa", "Obuasi", "Akosombo", 
      "Berekum", "Sefwi Wiawso", "Nkoranza", "Bolgatanga", "Wa", "Chirano", "Bawku", "Ejisu", "Nungua"
  ]},
  {name:"Uganda",
    cities:[
      "Kampala", "Entebbe", "Mbarara", "Jinja", "Mbale", "Masaka", "Fort Portal", "Lira", "Gulu", 
      "Kabale", "Arua", "Kasese", "Soroti", "Kampala", "Masindi", "Bushenyi", "Hoima", "Nakasongola", 
      "Kiryandongo", "Pader", "Kyenjojo", "Kagadi", "Iganga", "Namutumba", "Buhweju", "Bombo", 
      "Kotido", "Kaliro", "Rakai", "Kassanda", "Nakaseke"
  ]},
  {name:"Kenya",
    cities:[
      "Nairobi", "Mombasa", "Kisumu", "Nakuru", "Eldoret", "Thika", "Kisii", "Machakos", 
      "Malindi", "Nyeri", "Meru", "Embu", "Kericho", "Kitale", "Voi", "Kakamega", 
      "Bungoma", "Lamu", "Murang'a", "Nandi", "Ruiru", "Kiambu", "Homabay", "Bomet", 
      "Siaya", "Migori", "Narok", "Garissa", "Wajir", "Mandera", "Baringo", "Taveta"
  ]},
  {name:"Canada",
    cities: [
      "Toronto", "Vancouver", "Montreal", "Calgary", "Ottawa", "Edmonton", "Winnipeg", 
      "Quebec City", "Hamilton", "Kitchener", "London", "Victoria", "Halifax", "Surrey", 
      "Burnaby", "Richmond", "Markham", "Laval", "Brampton", "Oshawa", "Burlington", 
      "St. John's", "Gatineau", "Saskatoon", "Regina", "Mississauga", "Kingston", "Abbotsford", 
      "Red Deer", "Thunder Bay", "Chicoutimi", "Prince George", "Saint-Jean-sur-Richelieu", 
      "Saint John", "Moncton", "Sherbrooke", "Niagara Falls", "Fredericton", "Sudbury", 
      "Peterborough", "Kamloops", "Lethbridge", "Nanaimo", "Courtenay", "New Westminster"
]}
];
var list = listOfCountry.filter((a,i)=>a.name === formData.country);
var Cities:string[] = [];
if(list.length !== 0 )
{
  Cities = list[0].cities;
}
const GetCompanyInfo = ()=>{
GetRequest("business/detail",{}).then((res)=>{
  if(res.status)
  {
    setBussinessInfo(res.data.business)
    setLogo(res.data.business.image);
    setFormData(res.data.business)
  }
})
}

useEffect(()=>{
GetCompanyInfo();
},[])
useEffect(()=>{
const country = listOfCountry.find((a,i)=>a.name === bussinessInfo.country);
  setFormData({
    ...formData,
    ...country
  })
},[bussinessInfo])
  return (
    <form
    onSubmit={handleSubmitForm}
    >
      <div className="business mt-3 w={40} h={40}">
        <p>Business Logo</p>
        <div className="business-logo">
          <center>
            <div className="avatar d-flex align-items-center justify-content-center position-relative">
              {logo ? (
                <img src={logo} alt="Uploaded Logo" className="uploaded-logo" />
              ) : (
                <span className="fs-2 fw-bold orange-text">
                  {String(bussinessInfo.name).split("").filter((a,i)=>i === 0).join("")}
                </span>
              )}
              {logoUploading &&<span style={{position:"absolute"}}>
              <BaseLoader />
              </span>}
            </div>
            <div className='mt-3'>
            <b>
              {bussinessInfo.name} 
            </b>
            </div>
            <div>
              <input
                id="logo-upload"
                type="file"
                accept="image/*"
                onChange={handleLogoChange}
                className="d-none"
              />
            </div>
          </center>
          <button
          type='button'
            className="business-logo-button"
            onClick={() => document.getElementById('logo-upload')?.click()}
          >
            Upload logo
          </button>
        </div>
        <small>Maximum size: 1152 x 720px, png or jpeg</small>
        <div className="business-name">
          <input
            disabled={true}
            name="name"
            type="text"
            placeholder="Company Name"
            value={bussinessInfo.name}
            className="custom-input"
          />
        </div>

        <div className="business-place">
          <p>Business Location</p>
          <div className="transport-check-content">
            <div className="mb-1">
              <select
              required
              value={formData?.country}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  country:e.target.value
                })
              }}
                className="form-select"
                style={{ width: '220px', padding: '4px' }}
              >
                <option value="">Select Country</option>
                {listOfCountry.map((country,index)=> {
                 var selected = false;
                 if(country.name === formData.country)
                 {
                  selected = true;
                 }
                 return <option  key={index} value={country.name}
                 selected={selected}
                 >{country.name}</option>
                })}
                </select>
            </div>
            <div className="mb-1">
              <select
              required
                value={formData?.city}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    city:e.target.value
                  })
                }}
                style={{ width: '220px', padding: '4px' }}
                className="form-select"
              >
                <option value="">Select City</option>
                {Cities.map((city,index)=> {
                  const checked = city === formData.city;
                  return <option key={index} value={city} selected={checked}>{city}</option>
                  })}
              </select>
            </div>
          </div>
        </div>

        <div className="pe-3">
          <BaseButton 
          type='submit'
          loading={loading}
          >
            Save changes
          </BaseButton>
        </div>
      </div>
    </form>
  );
};

